.page_wrapper{
  font-family: 'Poppins', sans-serif;
  .hero_banner{
    color: white;
    background-image: url("../assets/images/Rectangle 60.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
    .left_info{
      h2{
        font-size: 43px;
        line-height: 1.4;
        font-weight: 700;
      }
      p{
        font-size: 25px;
        margin: 25px auto;
      }
    }
  }
}